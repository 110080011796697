import * as React from 'react'
import Modal from 'react-modal'

//styles
import './Modals.scss'

//assets
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

const ModalBase: React.FC<IModalBaseProps> = ({
  isOpen,
  onClose,
  wrapperClassName,
  children,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    className={`modal-content ${wrapperClassName ? wrapperClassName : ''}`}
    overlayClassName="modal-overlay"
    htmlOpenClassName="no-scroll"
    appElement={
      typeof window !== 'undefined'
        ? (document.getElementById('root') as HTMLElement)
        : undefined
    }
  >
    <button className="modal-close" type="button" onClick={onClose}>
      <CloseIcon />
    </button>
    {children}
  </Modal>
)

export default ModalBase
