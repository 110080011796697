import * as authActions from './authAction'
import * as accountActions from './accountAction'
import * as referralActions from './referralAction'
import * as diseaseActions from './diseaseAction'
import * as dashboardActions from './dashboardAction'
import * as facilitiesActions from './facilitiesAction'
import * as utilityActions from './utilityAction'

export default {
  ...authActions,
  ...accountActions,
  ...referralActions,
  ...diseaseActions,
  ...dashboardActions,
  ...facilitiesActions,
  ...utilityActions,
}
