import * as React from 'react'

//styles
import './Header.scss'

//components
import UserMenu from '../UserMenu/UserMenu'
import Notifications from '../Notifications/Notifications'

const Header: React.FC = () => {
  return (
    <header className="header container">
      <div className="dropdowns-wrapper">
        <UserMenu />
        <Notifications />
      </div>
    </header>
  )
}

export default Header
