import { combineReducers } from 'redux'

//reducers
import { authReducer } from './authReducer'
import { accountReducer } from './accountReducer'
import { referralReducer } from './referralReducer'
import { diseaseReducer } from './diseaseReducer'
import { dashboardReducer } from './dashboardReducer'
import { facilitiesReducer } from './facilitiesReducer'
import { utilityReducer } from './utilityReducer'

export const rootReducer = combineReducers({
  authUser: authReducer,
  accounts: accountReducer,
  referrals: referralReducer,
  diseases: diseaseReducer,
  dashboard: dashboardReducer,
  facilities: facilitiesReducer,
  utility: utilityReducer,
})
