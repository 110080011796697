import * as React from 'react'

//components
import Modal from './Modal'
import Button from '../Button/Button'

const HelpModal: React.FC<Omit<IModalBaseProps, 'wrapperClassName'>> = ({
  isOpen,
  onClose,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} wrapperClassName="modal-help">
    <p className="title">Help</p>
    <div className="help-section">
      <p>
        Having a problem with a referral or your account? Contact your
        administrator for help.
      </p>
      <Button href="mailto:admin@vnsc.org" external>
        Contact Admin
      </Button>
    </div>
    <div className="help-section">
      <p>Find a bug or technical issue? Contact support to report the issue.</p>
      <Button href="mailto:admin@vnsc.org" external>
        Contact Support
      </Button>
    </div>
  </Modal>
)

export default HelpModal
