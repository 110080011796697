export const DISEASE_FETCH = 'DISEASE_FETCH'
export const DISEASE_FETCH_SUCCESS = 'DISEASE_FETCH_SUCCESS'
export const DISEASE_FETCH_ERROR = 'DISEASE_FETCH_ERROR'

export const DISEASE_CREATE = 'DISEASE_CREATE'
export const DISEASE_CREATE_SUCCESS = 'DISEASE_CREATE_SUCCESS'

export const DISEASE_DELETE = 'DISEASE_DELETE'
export const DISEASE_DELETE_SUCCESS = 'DISEASE_DELETE_SUCCESS'

export const DISEASE_SUBMIT = 'DISEASE_SUBMIT'
export const DISEASE_SUBMIT_SUCCESS = 'DISEASE_SUBMIT_SUCCESS'
export const DISEASE_SUBMIT_ERROR = 'DISEASE_SUBMIT_ERROR'

export const DISEASE_CANCEL_SUBMIT = 'DISEASE_CANCEL_SUBMIT'
export const DISEASE_CANCEL_SUBMIT_SUCCESS = 'DISEASE_CANCEL_SUBMIT_SUCCESS'

export interface IDiseaseState {
  diseases: IDisease[]
  editedDiseases: IDisease[]
  toAdd: string[]
  toDelete: string[]
  loading: boolean
  error: string | null
}

interface IDiseaseFetchAction {
  type: typeof DISEASE_FETCH
}
interface IDiseaseFetchSuccessAction {
  type: typeof DISEASE_FETCH_SUCCESS
  payload: IDisease[]
}
interface IDiseaseFetchErrorAction {
  type: typeof DISEASE_FETCH_ERROR
  payload: string
}

interface IDiseaseCreateAction {
  type: typeof DISEASE_CREATE
}
interface IDiseaseCreateSuccessAction {
  type: typeof DISEASE_CREATE_SUCCESS
  payload: string
}

interface IDiseaseDeleteAction {
  type: typeof DISEASE_DELETE
}
interface IDiseaseDeleteSuccessAction {
  type: typeof DISEASE_DELETE_SUCCESS
  payload: {
    id: string
    name: string
  }
}

interface IDiseaseSubmitAction {
  type: typeof DISEASE_SUBMIT
}
interface IDiseaseSubmitSuccessAction {
  type: typeof DISEASE_SUBMIT_SUCCESS
  payload: IDisease[]
}
interface IDiseaseSubmitErrorAction {
  type: typeof DISEASE_SUBMIT_ERROR
  payload: string
}

interface IDiseaseCancelSubmitAction {
  type: typeof DISEASE_CANCEL_SUBMIT
}
interface IDiseaseCancelSubmitSuccessAction {
  type: typeof DISEASE_CANCEL_SUBMIT_SUCCESS
}

export type TDiseaseAction =
  | IDiseaseFetchAction
  | IDiseaseFetchSuccessAction
  | IDiseaseFetchErrorAction
  | IDiseaseCreateAction
  | IDiseaseCreateSuccessAction
  | IDiseaseDeleteAction
  | IDiseaseDeleteSuccessAction
  | IDiseaseSubmitAction
  | IDiseaseSubmitSuccessAction
  | IDiseaseSubmitErrorAction
  | IDiseaseCancelSubmitAction
  | IDiseaseCancelSubmitSuccessAction
