import * as React from 'react'
import { useState } from 'react'

//styles
import './Sidebar.scss'

//assets
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg'
import { ReactComponent as HelpIcon } from '../../../assets/icons/help.svg'

//components
import SidebarMenu from '../SidebarMenu/SidebarMenu'
import HelpModal from '../../Modals/HelpModal'

const Sidebar: React.FC = () => {
  const [helpModalOpen, setHelpModalOpen] = useState(false)

  return (
    <aside className="sidebar">
      <LogoIcon />
      <p className="sidebar-title">PATIENT REFERRAL PORTAL</p>
      <SidebarMenu />
      <button className="sidebar-help" onClick={() => setHelpModalOpen(true)}>
        <HelpIcon />
        Help
      </button>
      <HelpModal
        isOpen={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />
    </aside>
  )
}

export default Sidebar
