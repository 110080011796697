import { IUtilityState, TUtilityAction } from '../../types/utilityTypes'

const initialState: IUtilityState = {
  url: '',
}

export const utilityReducer = (
  state = initialState,
  action: TUtilityAction,
): IUtilityState => {
  switch (action.type) {
    case 'SAVE_URL':
      return { ...state, url: action.payload }
    default:
      return state
  }
}
