import * as React from 'react'

//components
import Sidebar from '../SidebarComponents/Sidebar/Sidebar'
import Header from '../HeaderComponents/Header/Header'
import Content from '../Content/Content'

//interfaces
// interface ILayoutProps {}

const Layout: React.FC = ({ children }) => {
  return (
    <div className="app-wrapper">
      <div className="app-container">
        <Header />
        <Sidebar />
        <Content>{children}</Content>
      </div>
    </div>
  )
}

export default Layout
