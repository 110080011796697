import axios from 'axios'

//services
import { login, logout } from './authService'
import { links } from '../utils/constants'

const baseService = axios.create({
  baseURL: 'https://' + process.env.REACT_APP_API_DOMAIN,
})

export const authCookieName = 'Authorization'
export const expiresCookieName = 'Expires'
export const refreshCookieName = 'refresh_token'
const TOKEN_LIMIT = 600000

export const attachUserToken = (token?: string): string | null => {
  const tokenToAttach = token || getUserToken()
  if (token) {
    sessionStorage.setItem(authCookieName, token)
  }

  if (tokenToAttach) {
    baseService.defaults.headers.common[
      authCookieName
    ] = `Bearer ${tokenToAttach}`
  }
  return tokenToAttach
}

export const getUserToken = (): string | null =>
  sessionStorage.getItem(authCookieName)

export const removeUserToken = (): void => {
  sessionStorage.removeItem(authCookieName)
  sessionStorage.removeItem(expiresCookieName)
  sessionStorage.removeItem('refresh_token')
  baseService.defaults.headers.common[authCookieName] = ''
}

baseService.interceptors.request.use(function (request) {
  const limit = sessionStorage.getItem(expiresCookieName)

  if (limit && Date.now() > parseInt(limit, 10)) {
    logout()
    window.location.replace(links.login)
  }

  if (
    !request.params?.unread &&
    !request.url?.endsWith('/users/otp') &&
    !request.url?.endsWith('/oauth/token') &&
    !request.url?.endsWith('/account')
  ) {
    sessionStorage.setItem(
      expiresCookieName,
      (Date.now() + TOKEN_LIMIT).toString(),
    )
  }
  return request
})

baseService.interceptors.response.use(
  function (response) {
    return response
  },
  async function (err) {
    const { response } = err
    if (response.status === 401) {
      try {
        const limit = sessionStorage.getItem(expiresCookieName)
        const refresh_token = sessionStorage.getItem(refreshCookieName)
        if (limit && refresh_token) {
          if (Date.now() < parseInt(limit, 10)) {
            await login({
              grant_type: 'refresh_token',
              client_id: process.env.REACT_APP_CLIENT_ID as string,
              client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
              refresh_token,
            })
          } else {
            logout()
            window.location.replace(links.login)
          }
        } else {
          logout()
          window.location.replace(links.login)
        }
      } catch {
        logout()
        window.location.replace(links.login)
      }
    } else {
      throw err
    }
  },
)

export default baseService
