import baseService, { attachUserToken, removeUserToken } from './baseService'

//services
import { getAccount } from './accountService'

export const getOTP = async (
  formData: Record<string, string>,
): Promise<void> => {
  await baseService.post('/api/users/otp', formData)
}

export const login = async (formData: ILoginForm): Promise<IAccount> => {
  const {
    data: { access_token, refresh_token },
  } = await baseService.post('/oauth/token', formData)
  sessionStorage.setItem('refresh_token', refresh_token)
  attachUserToken(access_token)

  return getAccount()
}

export const logout = (): void => {
  removeUserToken()
}

export const forgotPassword = async (email: string): Promise<void> => {
  await baseService.post('/api/users/passwords', {
    email,
  })
}

export const resetPassword = async (
  password: string,
  password_confirmation: string,
  reset_password_token: string,
): Promise<void> => {
  await baseService.put('/api/users/passwords', {
    password,
    password_confirmation,
    reset_password_token,
  })
}

export const createNewPassword = async (
  invitation_token: string,
  password: string,
): Promise<void> => {
  await baseService.post('/api/users/invitations', {
    invitation_token,
    password,
  })
}

export const confirmEmail = async (
  confirmation_token: string,
): Promise<void> => {
  await baseService.get(
    `/api/users/confirmation?confirmation_token=${confirmation_token}`,
  )
}
