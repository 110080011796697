import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//styles
import './styles/App.scss'
import './styles/Typography.scss'

//components
import VNSCRouter from './Router/Router'

import { Provider } from 'react-redux'
import { store } from './redux/store'

const App: React.FC = () => (
  <Provider store={store}>
    <VNSCRouter />
    <ToastContainer />
  </Provider>
)

export default App
