import {
  DASHBOARD_FETCH,
  DASHBOARD_FETCH_ERROR,
  DASHBOARD_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH,
  NOTIFICATIONS_FETCH_ERROR,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_READ_ERROR,
  NOTIFICATIONS_READ_SUCCESS,
  NOTIFICATIONS_UNREAD_FETCH,
  NOTIFICATIONS_UNREAD_FETCH_ERROR,
  NOTIFICATIONS_UNREAD_FETCH_SUCCESS,
  IDashboardState,
  TDashboardAction,
} from '../../types/dashboardTypes'

const initialState: IDashboardState = {
  dashboard: {} as IDashboard,
  notifications: [],
  unreadNotifications: [],
  loading: false,
  loadingUnread: false,
  error: null,
}

export const dashboardReducer = (
  state = initialState,
  action: TDashboardAction,
): IDashboardState => {
  switch (action.type) {
    case DASHBOARD_FETCH:
      return { ...state, loading: true }
    case DASHBOARD_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
      }
    case DASHBOARD_FETCH_ERROR:
      return { ...state, loading: false, error: action.payload }

    case NOTIFICATIONS_FETCH:
      return { ...state, loading: true }
    case NOTIFICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      }
    case NOTIFICATIONS_FETCH_ERROR:
      return { ...state, loading: false, error: action.payload }

    case NOTIFICATIONS_UNREAD_FETCH:
      return { ...state, loadingUnread: true }
    case NOTIFICATIONS_UNREAD_FETCH_SUCCESS:
      return {
        ...state,
        loadingUnread: false,
        unreadNotifications: action.payload,
      }
    case NOTIFICATIONS_UNREAD_FETCH_ERROR:
      return { ...state, loadingUnread: false, error: action.payload }

    case NOTIFICATIONS_READ:
      return { ...state, loading: true }
    case NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadNotifications: state.unreadNotifications.filter(
          (notification) => notification.id !== action.payload,
        ),
      }
    case NOTIFICATIONS_READ_ERROR:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
