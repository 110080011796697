import * as React from 'react'
import { NavLink } from 'react-router-dom'

//styles
import './SidebarMenu.scss'

//assets
import { ReactComponent as AdminIcon } from '../../../assets/icons/color.svg'
import { ReactComponent as DashboardIcon } from '../../../assets/icons/dashboard.svg'
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notification.svg'
import { ReactComponent as ReferralsIcon } from '../../../assets/icons/project.svg'
import { ReactComponent as StaffIcon } from '../../../assets/icons/team.svg'

//utils
import { links } from '../../../utils/constants'
import { useSelectorTyped } from '../../../utils/hooks/useSelectorTyped'

const SidebarMenu: React.FC = () => {
  const {
    attributes: { admin },
  } = useSelectorTyped(({ authUser }) => authUser.currentUser)
  return (
    <nav className="sidebar-nav">
      <ul className="sidebar-menu">
        <li>
          <NavLink
            className="sidebar-link"
            activeClassName="active"
            to={links.dashboard}
            exact
          >
            <DashboardIcon className="sidebar-link-icon" />
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            className="sidebar-link"
            activeClassName="active"
            to={links.notificationsPage}
          >
            <NotificationIcon className="sidebar-link-icon" />
            Notifications
          </NavLink>
        </li>
        <li>
          <NavLink
            className="sidebar-link"
            activeClassName="active"
            to={links.referrals}
          >
            <ReferralsIcon className="sidebar-link-icon" />
            Referrals
          </NavLink>
        </li>
        <li>
          <NavLink
            className="sidebar-link"
            activeClassName="active"
            to={links.staff}
          >
            <StaffIcon className="sidebar-link-icon" />
            Staff
          </NavLink>
        </li>
        {admin && (
          <li>
            <NavLink
              className="sidebar-link"
              activeClassName="active"
              to={links.admin}
            >
              <AdminIcon className="sidebar-link-icon" />
              Administrator
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default SidebarMenu
