import {
  IReferralState,
  REFERRAL_FETCH,
  REFERRAL_FETCH_ERROR,
  REFERRAL_FETCH_ONE,
  REFERRAL_FETCH_ONE_ERROR,
  REFERRAL_FETCH_ONE_SUCCESS,
  REFERRAL_FETCH_SUCCESS,
  REFERRAL_UPDATE_ONE,
  REFERRAL_UPDATE_ONE_ERROR,
  REFERRAL_UPDATE_ONE_SUCCESS,
  TReferralAction,
} from '../../types/referralTypes'

const initialState: IReferralState = {
  referrals: [],
  selectedReferral: {} as IReferral,
  loading: false,
  error: null,
}

export const referralReducer = (
  state = initialState,
  action: TReferralAction,
): IReferralState => {
  switch (action.type) {
    case REFERRAL_FETCH:
      return { ...state, loading: true }
    case REFERRAL_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        referrals: action.payload,
        error: null,
      }
    case REFERRAL_FETCH_ERROR:
      return { ...state, loading: false, error: action.payload }

    case REFERRAL_FETCH_ONE:
      return { ...state, loading: true }
    case REFERRAL_FETCH_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedReferral: action.payload,
        error: null,
      }
    case REFERRAL_FETCH_ONE_ERROR:
      return { ...state, loading: false, error: action.payload }

    case REFERRAL_UPDATE_ONE:
      return { ...state, loading: true }
    case REFERRAL_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedReferral: action.payload,
        error: null,
      }
    case REFERRAL_UPDATE_ONE_ERROR:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
