import { format } from 'date-fns'

import { patientStatus } from './constants'

export const getStatusText = (status: string): string | undefined => {
  for (const statusItem of patientStatus) {
    if (statusItem.value === status) {
      return statusItem.label
    }
  }
}

export const formatDate = (date: string): string =>
  format(
    new Date(
      date.endsWith('UTC') ? date.replace(' UTC', 'Z').replace(' ', 'T') : date,
    ),
    'MM/d/yyyy, h:mmaaa',
  )

export const formatImageUrl = (url: string): string =>
  url.includes('http')
    ? url
    : `https://${process.env.REACT_APP_API_DOMAIN}${url}`

export const getNotificationText = (
  attributes: INotificationAttributes,
): string =>
  attributes.meta.author.type === 'patient'
    ? `Patient ${attributes.authorable.first_name} ${attributes.authorable.last_name} referred themselves`
    : `Dr. ${attributes.authorable.first_name} ${attributes.authorable.last_name} sent a referral`
