export const REFERRAL_FETCH = 'REFERRAL_FETCH'
export const REFERRAL_FETCH_SUCCESS = 'REFERRAL_FETCH_SUCCESS'
export const REFERRAL_FETCH_ERROR = 'REFERRAL_FETCH_ERROR'

export const REFERRAL_FETCH_ONE = 'REFERRAL_FETCH_ONE'
export const REFERRAL_FETCH_ONE_SUCCESS = 'REFERRAL_FETCH_ONE_SUCCESS'
export const REFERRAL_FETCH_ONE_ERROR = 'REFERRAL_FETCH_ONE_ERROR'

export const REFERRAL_UPDATE_ONE = 'REFERRAL_UPDATE_ONE'
export const REFERRAL_UPDATE_ONE_SUCCESS = 'REFERRAL_UPDATE_ONE_SUCCESS'
export const REFERRAL_UPDATE_ONE_ERROR = 'REFERRAL_UPDATE_ONE_ERROR'

export interface IReferralState {
  referrals: IReferral[]
  selectedReferral: IReferral
  loading: boolean
  error: string | null
}

interface IReferralFetchAction {
  type: typeof REFERRAL_FETCH
}
interface IReferralFetchSuccessAction {
  type: typeof REFERRAL_FETCH_SUCCESS
  payload: IReferral[]
}
interface IReferralFetchErrorAction {
  type: typeof REFERRAL_FETCH_ERROR
  payload: string
}

interface IReferralFetchOneAction {
  type: typeof REFERRAL_FETCH_ONE
}
interface IReferralFetchOneSuccessAction {
  type: typeof REFERRAL_FETCH_ONE_SUCCESS
  payload: IReferral
}
interface IReferralFetchOneErrorAction {
  type: typeof REFERRAL_FETCH_ONE_ERROR
  payload: string
}

interface IReferralUpdateOneAction {
  type: typeof REFERRAL_UPDATE_ONE
}
interface IReferralUpdateOneSuccessAction {
  type: typeof REFERRAL_UPDATE_ONE_SUCCESS
  payload: IReferral
}
interface IReferralUpdateOneErrorAction {
  type: typeof REFERRAL_UPDATE_ONE_ERROR
  payload: string
}

export type TReferralAction =
  | IReferralFetchAction
  | IReferralFetchSuccessAction
  | IReferralFetchErrorAction
  | IReferralFetchOneAction
  | IReferralFetchOneSuccessAction
  | IReferralFetchOneErrorAction
  | IReferralUpdateOneAction
  | IReferralUpdateOneSuccessAction
  | IReferralUpdateOneErrorAction
