import { Dispatch } from 'redux'

//types
import {
  DISEASE_CANCEL_SUBMIT,
  DISEASE_CANCEL_SUBMIT_SUCCESS,
  DISEASE_CREATE,
  DISEASE_CREATE_SUCCESS,
  DISEASE_DELETE,
  DISEASE_DELETE_SUCCESS,
  DISEASE_FETCH,
  DISEASE_FETCH_ERROR,
  DISEASE_FETCH_SUCCESS,
  DISEASE_SUBMIT,
  DISEASE_SUBMIT_ERROR,
  DISEASE_SUBMIT_SUCCESS,
  TDiseaseAction,
} from '../../types/diseaseTypes'

//services
import { getDiseases, saveDiseases } from '../../../services/diseasesService'

export const fetchDiseases = () => {
  return async (dispatch: Dispatch<TDiseaseAction>): Promise<void> => {
    try {
      dispatch({ type: DISEASE_FETCH })
      const data = await getDiseases()
      dispatch({ type: DISEASE_FETCH_SUCCESS, payload: data as IDisease[] })
    } catch (e) {
      dispatch({ type: DISEASE_FETCH_ERROR, payload: e.message })
    }
  }
}

export const addDisease = (name: string) => {
  return (dispatch: Dispatch<TDiseaseAction>): void => {
    dispatch({ type: DISEASE_CREATE })
    dispatch({ type: DISEASE_CREATE_SUCCESS, payload: name })
  }
}

export const removeDisease = (id: string, name: string) => {
  return (dispatch: Dispatch<TDiseaseAction>): void => {
    dispatch({ type: DISEASE_DELETE })
    dispatch({ type: DISEASE_DELETE_SUCCESS, payload: { id, name } })
  }
}

export const submitDiseases = (formData: IDiseaseForm) => {
  return async (dispatch: Dispatch<TDiseaseAction>): Promise<void> => {
    try {
      dispatch({ type: DISEASE_SUBMIT })
      const data = await saveDiseases(formData)
      dispatch({ type: DISEASE_SUBMIT_SUCCESS, payload: data as IDisease[] })
    } catch (e) {
      dispatch({ type: DISEASE_SUBMIT_ERROR, payload: e.message })
    }
  }
}

export const cancelRemoveDisease = () => {
  return (dispatch: Dispatch<TDiseaseAction>): void => {
    dispatch({ type: DISEASE_CANCEL_SUBMIT })
    dispatch({ type: DISEASE_CANCEL_SUBMIT_SUCCESS })
  }
}
