import { RefObject, useEffect, useState } from 'react'

export const useDropdown = (
  ref: RefObject<HTMLDivElement>,
  initialState = false,
): [boolean, (value: boolean) => void] => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState)

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target as HTMLDivElement)) {
        setIsOpen(!isOpen)
      }
    }
    if (isOpen) {
      window.addEventListener('click', onClick)
    }
    return () => {
      window.removeEventListener('click', onClick)
    }
  }, [isOpen, ref])

  return [isOpen, setIsOpen]
}
