import baseService from './baseService'

export const getDiseases = async (): Promise<IDisease[]> => {
  const {
    data: { data },
  } = await baseService.get(`/api/admin/tags`)
  return data
}

export const createDisease = async (
  formData: IDiseaseForm,
): Promise<IDisease> => {
  const {
    data: { data },
  } = await baseService.post('api/admin/tags', {
    disease: formData,
  })
  return data
}

export const deleteDisease = async (id: string): Promise<string> => {
  const {
    data: {
      data: { message },
    },
  } = await baseService.delete(`/api/admin/tags/${id}`)
  return message
}

export const saveDiseases = async (
  formData: IDiseaseForm,
): Promise<IDisease[]> => {
  const {
    data: { data },
  } = await baseService.post('/api/admin/tags/save', {
    tag: formData,
  })
  return data
}
