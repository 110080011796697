import {
  ACCOUNT_CREATE,
  ACCOUNT_CREATE_ERROR,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_DELETE,
  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_FETCH,
  ACCOUNT_FETCH_ERROR,
  ACCOUNT_FETCH_ONE,
  ACCOUNT_FETCH_ONE_ERROR,
  ACCOUNT_FETCH_ONE_SUCCESS,
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_GET_REFERRALS,
  ACCOUNT_GET_REFERRALS_ERROR,
  ACCOUNT_GET_REFERRALS_SUCCESS,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_STATUS,
  ACCOUNT_UPDATE_STATUS_ERROR,
  ACCOUNT_UPDATE_STATUS_SUCCESS,
  ACCOUNT_UPDATE_SUCCESS,
  AVATAR_UPLOAD,
  AVATAR_UPLOAD_ERROR,
  AVATAR_UPLOAD_SUCCESS,
  IAccountState,
  TAccountAction,
} from '../../types/accountTypes'

const initialState: IAccountState = {
  accounts: [],
  selectedAccount: {} as IAccount,
  accountReferrals: [] as IReferral[],
  loading: false,
  error: null,
  admins: {} as IAdminsMeta,
}

export const accountReducer = (
  state = initialState,
  action: TAccountAction,
): IAccountState => {
  switch (action.type) {
    case ACCOUNT_FETCH:
      return { ...state, loading: true }
    case ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: action.payload.accounts,
        admins: action.payload.admins,
      }
    case ACCOUNT_FETCH_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ACCOUNT_FETCH_ONE:
      return { ...state, loading: true }
    case ACCOUNT_FETCH_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAccount: action.payload.account,
        admins: action.payload.admins,
      }
    case ACCOUNT_FETCH_ONE_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ACCOUNT_GET_REFERRALS:
      return { ...state, loading: true }
    case ACCOUNT_GET_REFERRALS_SUCCESS:
      return { ...state, loading: false, accountReferrals: action.payload }
    case ACCOUNT_GET_REFERRALS_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ACCOUNT_CREATE:
      return { ...state, loading: true }
    case ACCOUNT_CREATE_SUCCESS:
      return { ...state, loading: false, selectedAccount: action.payload }
    case ACCOUNT_CREATE_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ACCOUNT_UPDATE:
      return { ...state, loading: true }
    case ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAccount: action.payload,
      }
    case ACCOUNT_UPDATE_ERROR:
      return { ...state, loading: false, error: action.payload }

    case AVATAR_UPLOAD:
      return { ...state, loading: true }
    case AVATAR_UPLOAD_SUCCESS:
      return { ...state, loading: false, selectedAccount: action.payload }
    case AVATAR_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ACCOUNT_UPDATE_STATUS:
      return { ...state, loading: true }
    case ACCOUNT_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: state.accounts.map((account) => {
          if (account.id === action.payload.id) {
            return action.payload
          }
          return account
        }),
      }
    case ACCOUNT_UPDATE_STATUS_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ACCOUNT_DELETE:
      return { ...state, loading: true }
    case ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: state.accounts.filter(({ id }) => id !== action.payload),
      }
    case ACCOUNT_DELETE_ERROR:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
