import * as React from 'react'
import { useCallback, useEffect, useRef } from 'react'

//styles
import './Notifications.scss'

//assets
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notification_bell.svg'

//components
import Button from '../../Button/Button'
import NotificationItem from '../../NotificationsComponents/NotificationItem/NotificationItem'

//utils
import { links } from '../../../utils/constants'
import { useDropdown } from '../../../utils/hooks/useDropdown'
import { getNotificationText } from '../../../utils/helpers'

//redux
import { useActions } from '../../../utils/hooks/useActions'
import { useSelectorTyped } from '../../../utils/hooks/useSelectorTyped'

const Notifications: React.FC = () => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useDropdown(ref)
  const onButtonClick = () => setIsOpen(!isOpen)

  const unread = useSelectorTyped(
    ({ dashboard }) => dashboard.unreadNotifications,
  )
  const { fetchUnreadNotifications, markAsRead } = useActions()

  const timeout = useRef<NodeJS.Timeout>()

  const getNotifications = useCallback(async () => {
    try {
      await fetchUnreadNotifications()
      timeout.current = setTimeout(async () => {
        await getNotifications()
      }, 10000)
    } catch {
      timeout.current = setTimeout(() => {
        getNotifications()
      }, 500)
    }
  }, [fetchUnreadNotifications])

  useEffect(() => {
    getNotifications()
    return () => {
      clearTimeout(timeout.current as NodeJS.Timeout)
    }
  }, [getNotifications])

  return (
    <div className="notifications-menu" onClick={onButtonClick}>
      <NotificationIcon />
      {unread.length > 0 && <span className="has-unread" />}
      {isOpen && (
        <div className="notifications-menu-body" ref={ref}>
          <ul>
            {unread.map(({ id, attributes }) => {
              return (
                <NotificationItem
                  id={attributes.referral.id}
                  text={getNotificationText(attributes)}
                  time={attributes.referral.updated_at}
                  small
                  key={id}
                  onDismiss={() => {
                    markAsRead(id)
                  }}
                />
              )
            })}
          </ul>
          <div className="menu-footer">
            <Button design="flat" color="black" href={links.notifications}>
              View All
            </Button>
            <Button design="flat" color="red" onClick={onButtonClick}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Notifications
