import * as React from 'react'
import { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

//components
import Layout from '../components/Layout/Layout'

//utils
import { links } from '../utils/constants'
import { useSelectorTyped } from '../utils/hooks/useSelectorTyped'
import { useActions } from '../utils/hooks/useActions'

//pages

const DashboardPage = lazy(() => import('../pages/DashboardPage/DashboardPage'))
const ProfilePage = lazy(() => import('../pages/ProfilePage/ProfilePage'))
const NotificationsPage = lazy(
  () => import('../pages/NotificationsPage/NotificationsPage'),
)
const ReferralsPage = lazy(() => import('../pages/ReferralsPage/ReferralsPage'))
const ReferralPage = lazy(() => import('../pages/ReferralPage/ReferralPage'))
const StaffPage = lazy(() => import('../pages/StaffPage/StaffPage'))
const StafferPage = lazy(() => import('../pages/StafferPage/StafferPage'))
const AdminPage = lazy(() => import('../pages/AdminPage/AdminPage'))
const NotFound = lazy(() => import('../pages/NotFound/NotFound'))

interface IAppRoutesProps {
  isAdmin: boolean
}

const AppRoutes: React.FC<IAppRoutesProps> = ({ isAdmin }) => {
  const { replace } = useHistory()
  const url = useSelectorTyped(({ utility }) => utility.url)
  const { saveUrl } = useActions()

  useEffect(() => {
    if (url) {
      replace(url)
      saveUrl('')
    }
  }, [replace, saveUrl, url])

  return (
    <Layout>
      <Suspense fallback={<p>loading...</p>}>
        <Switch>
          <Redirect to={links.dashboard} from={links.login} />

          {isAdmin && (
            <Route path={links.create_staffer} exact>
              <ProfilePage isCreate isAdmin />
            </Route>
          )}
          {isAdmin && (
            <Route path={links.edit_staffer} exact>
              <ProfilePage isEdit isAdmin />
            </Route>
          )}
          {isAdmin && (
            <Route path={links.create_medical} exact>
              <ProfilePage isCreate isMedical isAdmin />
            </Route>
          )}
          {isAdmin && (
            <Route path={links.admin}>
              <AdminPage />
            </Route>
          )}

          <Route path={links.dashboard} exact>
            <DashboardPage />
          </Route>
          <Route path={links.profile} exact>
            <ProfilePage isEdit />
          </Route>
          <Route path={links.notificationsPage}>
            <NotificationsPage />
          </Route>

          {/*referrals tab*/}
          <Route path={links.referrals} exact>
            <ReferralsPage />
          </Route>
          <Route path={links.referringDoctor}>
            <StafferPage isReferring />
          </Route>
          <Route path={links.referralDetails}>
            <ReferralPage />
          </Route>

          {/*staff tab*/}
          <Route path={links.staff} exact>
            <StaffPage />
          </Route>
          <Route path={links.stafferDetails}>
            <StafferPage />
          </Route>

          <Route path={links.not_found}>
            <NotFound />
          </Route>
          <Redirect to={links.not_found} />
        </Switch>
      </Suspense>
    </Layout>
  )
}

export default AppRoutes
