import * as React from 'react'

//styles
import './Content.scss'

//interfaces
// interface IContentProps {}

const Content: React.FC = ({ children }) => {
  return <main className="content container">{children}</main>
}

export default Content
