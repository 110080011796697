import baseService from './baseService'

export const getReferrals = async (
  params?: IQueryParams,
): Promise<IPaginatedResponse<IReferral>> => {
  const { data } = await baseService.get('/api/dashboard/referrals', {
    params,
  })
  return data
}

export const getOneReferral = async (id: string): Promise<IReferral> => {
  const {
    data: { data },
  } = await baseService.get(`/api/dashboard/referrals/${id}`)
  return data
}

export const updateReferral = async (
  id: string,
  formData: Record<string, string>,
): Promise<IReferral> => {
  const {
    data: { data },
  } = await baseService.post(`/api/referrals/${id}/notes`, {
    referral: formData,
  })
  return data
}
