import baseService from './baseService'

export const getAccount = async (): Promise<IAccount> => {
  const {
    data: { data },
  } = await baseService.get('/api/account')
  return data
}

export const updateAccount = async (
  id: string,
  updatedData: Partial<IAccountAttributes>,
): Promise<IAccount> => {
  const {
    data: { data },
  } = await baseService.put(`/api/accounts/${id}`, { account: updatedData })
  return data
}

export const getOneAccount = async (
  id: string,
): Promise<Record<string, any>> => {
  const { data } = await baseService.get(`/api/accounts/${id}`)
  return data
}

export const getAccounts = async (
  params?: IQueryParams,
): Promise<IPaginatedResponse<IAccount>> => {
  const { data } = await baseService.get(`/api/accounts`, {
    params,
  })
  return data
}

export const getAccountReferrals = async (
  accountId: string,
  params?: IQueryParams,
): Promise<IPaginatedResponse<IReferral>> => {
  const { data } = await baseService.get(
    `/api/dashboard/accounts/${accountId}/referrals`,
    {
      params,
    },
  )
  return data
}

export const createAccount = async (
  formData: ICreateAccountForm,
): Promise<IAccount> => {
  const {
    data: { data },
  } = await baseService.post('/api/dashboard/accounts', formData)
  return data
}

export const editAccount = async (
  id: string,
  updatedData: Partial<IAccountAttributes>,
): Promise<IAccount> => {
  const {
    data: { data },
  } = await baseService.put(`/api/dashboard/accounts/${id}`, {
    account: updatedData,
  })
  return data
}

export const uploadAvatar = async (
  id: string,
  avatarBlob: Blob,
): Promise<IAccount> => {
  const avatar = new FormData()
  avatar.append('avatar', avatarBlob)
  const {
    data: { data },
  } = await baseService.post(`/api/accounts/${id}/avatar`, avatar, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return data
}

export const editAccountStatuses = async (
  account_id: string,
  user_id: string,
  updatedData: Partial<IAccountAttributes>,
): Promise<IAccount> => {
  const {
    data: { data },
  } = await baseService.put(`/api/dashboard/accounts/${account_id}`, {
    account: {
      user_attributes: {
        id: user_id,
        ...updatedData,
      },
    },
  })
  return data
}

export const deleteAccount = async (
  id: string,
  confirmation: string,
): Promise<IAccountAttributes> => {
  const {
    data: { data },
  } = await baseService.delete(`/api/admin/staff/${id}`, {
    params: { confirmation },
  })
  return data
}

export const adminGetStaff = async (
  params: IQueryParams = {},
): Promise<IPaginatedResponse<IAccount>> => {
  const { data } = await baseService.get('/api/admin/staff', { params })
  return data
}
