import * as React from 'react'
import { lazy, Suspense, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

//utils
import { links } from '../utils/constants'
import { useActions } from '../utils/hooks/useActions'

//pages
const LoginPage = lazy(() => import('../pages/LoginPage/LoginPage'))
const ResetPasswordPage = lazy(
  () => import('../pages/ResetPasswordPage/ResetPasswordPage'),
)
const CreatePasswordPage = lazy(
  () => import('../pages/CreatePasswordPage/CreatePasswordPage'),
)
const ConfirmEmailPage = lazy(
  () => import('../pages/ConfirmEmailPage/ConfirmEmailPage'),
)

const loginRoutes = [
  links.login,
  links.reset_password,
  links.create_password,
  links.new_user_password,
  links.emailConfirmation,
]

const AuthRoutes: React.FC = () => {
  const { pathname, search } = useLocation()
  const { saveUrl } = useActions()

  useEffect(() => {
    if (!loginRoutes.includes(pathname) && pathname !== '/') {
      saveUrl(`${pathname}${search ? search : ''}`)
    }
  })

  return (
    <Suspense fallback={<p>loading...</p>}>
      <Switch>
        <Route path={links.login} exact>
          <LoginPage />
        </Route>
        <Route path={links.reset_password} exact>
          <ResetPasswordPage />
        </Route>
        <Route path={links.create_password}>
          <CreatePasswordPage />
        </Route>
        <Route path={links.new_user_password}>
          <CreatePasswordPage />
        </Route>
        <Route path={links.emailConfirmation}>
          <ConfirmEmailPage />
        </Route>
        <Redirect to={links.login} />
      </Switch>
    </Suspense>
  )
}

export default AuthRoutes
