import { customAlphabet } from 'nanoid'

import {
  DISEASE_CANCEL_SUBMIT,
  DISEASE_CANCEL_SUBMIT_SUCCESS,
  DISEASE_CREATE,
  DISEASE_CREATE_SUCCESS,
  DISEASE_DELETE,
  DISEASE_DELETE_SUCCESS,
  DISEASE_FETCH,
  DISEASE_FETCH_ERROR,
  DISEASE_FETCH_SUCCESS,
  DISEASE_SUBMIT,
  DISEASE_SUBMIT_ERROR,
  DISEASE_SUBMIT_SUCCESS,
  IDiseaseState,
  TDiseaseAction,
} from '../../types/diseaseTypes'

const initialState: IDiseaseState = {
  diseases: [],
  editedDiseases: [],
  toAdd: [],
  toDelete: [],
  loading: false,
  error: null,
}

export const diseaseReducer = (
  state = initialState,
  action: TDiseaseAction,
): IDiseaseState => {
  switch (action.type) {
    case DISEASE_FETCH:
      return { ...state, loading: true }
    case DISEASE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        diseases: action.payload,
        editedDiseases: action.payload,
      }
    }
    case DISEASE_FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload }
    }

    case DISEASE_CREATE:
      return { ...state, loading: true }
    case DISEASE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        editedDiseases: [
          ...state.editedDiseases,
          {
            id: customAlphabet('abcdefghijklmnopqrstuvwxyz', 4)(),
            attributes: { name: action.payload },
          } as IDisease,
        ],
        toAdd: [...state.toAdd, action.payload],
      }

    case DISEASE_DELETE:
      return {
        ...state,
        loading: true,
      }
    case DISEASE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        editedDiseases: state.editedDiseases.filter(
          ({ id }) => id !== action.payload.id,
        ),
        toDelete: isNaN(parseInt(action.payload.id))
          ? state.toDelete
          : [...state.toDelete, action.payload.id],
        toAdd: state.toAdd.filter((item) => item !== action.payload.name),
      }

    case DISEASE_SUBMIT:
      return { ...state, loading: true }
    case DISEASE_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        diseases: action.payload,
        editedDiseases: action.payload,
        toAdd: [],
        toDelete: [],
      }
    case DISEASE_SUBMIT_ERROR:
      return {
        ...state,
        error: action.payload,
        editedDiseases: state.diseases,
        toAdd: [],
        toDelete: [],
      }

    case DISEASE_CANCEL_SUBMIT:
      return { ...state, loading: true }
    case DISEASE_CANCEL_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        editedDiseases: state.diseases,
        toAdd: [],
        toDelete: [],
      }

    default:
      return state
  }
}
