import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { TAppDispatch } from '../../redux/store'
import { useMemo } from 'react'
import ActionCreators from '../../redux/store/action-creators'

export const useActions = (): ReturnType<typeof bindActionCreators> => {
  const dispatch = useDispatch<TAppDispatch>()
  return useMemo(() => {
    return bindActionCreators(ActionCreators, dispatch)
  }, [dispatch])
}
