export const SAVE_URL = 'SAVE_URL'

export interface IUtilityState {
  url: string
}

interface IReferralFetchAction {
  type: typeof SAVE_URL
  payload: string
}

export type TUtilityAction = IReferralFetchAction
