export const FACILITIES_FETCH = 'FACILITIES_FETCH'
export const FACILITIES_FETCH_SUCCESS = 'FACILITIES_FETCH_SUCCESS'
export const FACILITIES_FETCH_ERROR = 'FACILITIES_FETCH_ERROR'

export interface IFacilitiesState {
  facilities: IFacility[]
  loading: boolean
  error: string | null
}

interface IFacilitiesFetchAction {
  type: typeof FACILITIES_FETCH
}
interface IFacilitiesFetchSuccessAction {
  type: typeof FACILITIES_FETCH_SUCCESS
  payload: IFacility[]
}
interface IFacilitiesFetchErrorAction {
  type: typeof FACILITIES_FETCH_ERROR
  payload: string
}

export type TFacilitiesAction =
  | IFacilitiesFetchAction
  | IFacilitiesFetchSuccessAction
  | IFacilitiesFetchErrorAction
