export const links = {
  //auth
  login: '/login',
  reset_password: '/reset-password',
  create_password: '/create-password',
  new_user_password: '/users/invitation/accept',
  emailConfirmation: '/confirmation',
  //main pages
  dashboard: '/',
  admin: '/admin',
  notificationsPage: '/notifications',
  notifications: '/notifications/notifications',
  updates: '/notifications/updates',
  referrals: '/referrals',
  staff: '/staff',
  profile: '/profile',
  //details pages
  referralDetails: '/referrals/:id',
  referringDoctor: '/referrals/referring/:id',
  stafferDetails: '/staff/:id',
  referral: (id: string): string => `/referrals/${id}`,
  staffer: (id: string): string => `/staff/${id}`,
  referringMedical: (id: string): string => `/referrals/referring/${id}`,
  //admin pages
  admin_staff: '/admin/staff',
  admin_notifications: '/admin/notifications',
  admin_users: '/admin/users',
  admin_diseases: '/admin/diseases',
  create_staffer: '/admin/staffer/create',
  edit_staffer: '/admin/staffer/:id/edit',
  stafferEdit: (id: string): string => `/admin/staffer/${id}/edit`,
  create_medical: '/admin/medical/create',

  not_found: '/page-not-found',
}

export const referralStatus: Record<string, string> = {
  not_contacted: 'Not Contacted',
  contacted: 'Contacted - Follow Up Needed',
  appointment_scheduled: 'Appointment Scheduled',
  processed: 'Processed',
  lost_lead: 'Lost Lead',
}

const values = Object.keys(referralStatus)
const labels = Object.values(referralStatus)
const temp: IOption[] = []
for (let i = 0; i < values.length; i++) {
  temp.push({ value: values[i], label: labels[i] })
}
export const patientStatus = temp

export const sortings: IOption[] = [
  { value: 'desc', label: 'Newest' },
  { value: 'asc', label: 'Oldest' },
]

export const alphabetic: IOption[] = [
  { value: 'desc', label: 'Z-A' },
  { value: 'asc', label: 'A-Z' },
]

export const titles: IOption[] = [
  { value: 'Dr.', label: 'Dr.' },
  { value: 'NP', label: 'NP' },
  { value: 'PA', label: 'PA' },
  { value: 'RN', label: 'RN' },
]

export const filters: IOption[] = [
  { value: '30', label: 'Last 30 days' },
  { value: '60', label: 'Last 60 days' },
  { value: '90', label: 'Last 90 days' },
  { value: '120', label: 'Last 120 days' },
  { value: '365', label: 'Last year' },
]

export const colors: Record<string, string> = {
  not_contacted: '#D22026',
  contacted: '#F2A818',
  appointment_scheduled: '#0BC196',
  processed: '#2B2B2B',
  lost_lead: '#B11B1B',
}

export const badgeClasses: Record<
  string,
  'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'error-accent'
> = {
  not_contacted: 'error',
  contacted: 'warning',
  appointment_scheduled: 'success',
  processed: 'secondary',
  lost_lead: 'error-accent',
}

export const referralUrgency = {
  urgent: 'Urgent',
  not_urgent: 'Not Urgent',
}

export const referralAffilation = {
  self_referral: 'Self-Referral',
  doctor: 'Medical Professional Referral',
  unlogged_doctor: 'Medical Professional Referral',
}
