import * as React from 'react'
import { useRef } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

//assets
import { ReactComponent as DropdownIcon } from '../../../assets/icons/arrow_drop_down.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg'

//styles
import './UserMenu.scss'

//utils
import { links } from '../../../utils/constants'

//hooks
import { useDropdown } from '../../../utils/hooks/useDropdown'

//redux
import { useActions } from '../../../utils/hooks/useActions'
import { useSelectorTyped } from '../../../utils/hooks/useSelectorTyped'

const UserMenu: React.FC = () => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useDropdown(ref)
  const onNameClick = () => setIsOpen(!isOpen)
  const { logoutUser, saveUrl } = useActions()
  const { first_name, last_name } = useSelectorTyped(
    ({ authUser }) => authUser.currentUser.attributes,
  )

  const onLogoutClick = () => {
    saveUrl('')
    logoutUser()
  }

  return (
    <div
      className={classNames('user-menu', { open: isOpen })}
      onClick={onNameClick}
    >
      {first_name} {last_name} <DropdownIcon className="user-menu-chevron" />
      {isOpen && (
        <div className="menu-body" ref={ref}>
          <Link className="menu-item" to={links.profile}>
            <UserIcon />
            Edit Your Profile
          </Link>
          <button className="menu-item" type="button" onClick={onLogoutClick}>
            <LogoutIcon />
            Sign Out
          </button>
        </div>
      )}
    </div>
  )
}

export default UserMenu
