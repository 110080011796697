import { Dispatch } from 'redux'

//services
import { attachUserToken } from '../../../services/baseService'
import {
  confirmEmail,
  createNewPassword,
  forgotPassword,
  getOTP,
  login,
  logout,
  resetPassword,
} from '../../../services/authService'
import { getAccount, updateAccount } from '../../../services/accountService'

//types
import {
  AUTH_GET_CURRENT,
  AUTH_GET_CURRENT_ERROR,
  AUTH_GET_CURRENT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_OTP,
  AUTH_OTP_ERROR,
  AUTH_OTP_SUCCESS,
  AUTH_UPDATE_CURRENT,
  AUTH_UPDATE_CURRENT_ERROR,
  AUTH_UPDATE_CURRENT_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  PASSWORD_CREATE,
  PASSWORD_CREATE_ERROR,
  PASSWORD_CREATE_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  TAuthAction,
} from '../../types/authTypes'

export const otp =
  (formData: Record<string, string>) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<boolean> => {
    try {
      dispatch({ type: AUTH_OTP })
      await getOTP(formData)
      dispatch({ type: AUTH_OTP_SUCCESS })
      return true
    } catch ({
      response: {
        data: { errors },
      },
    }) {
      dispatch({ type: AUTH_OTP_ERROR, payload: errors })
      return false
    }
  }

export const loginUser =
  (formData: ILoginForm) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: AUTH_LOGIN })
      const response = await login(formData)
      dispatch({ type: AUTH_LOGIN_SUCCESS, payload: response })
    } catch ({ response }) {
      dispatch({ type: AUTH_LOGIN_ERROR, payload: response.data.message })
    }
  }

export const getCurrentUser =
  () =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      attachUserToken()
      dispatch({ type: AUTH_GET_CURRENT })
      const response = await getAccount()
      dispatch({ type: AUTH_GET_CURRENT_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: AUTH_GET_CURRENT_ERROR, payload: e.message })
    }
  }

export const updateCurrentUser =
  (id: string, updatedData: Partial<IAccountAttributes>) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      attachUserToken()
      dispatch({ type: AUTH_UPDATE_CURRENT })
      const response = await updateAccount(id, updatedData)
      dispatch({ type: AUTH_UPDATE_CURRENT_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: AUTH_UPDATE_CURRENT_ERROR, payload: e.message })
    }
  }

export const logoutUser =
  () =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: AUTH_LOGOUT })
      await logout()
      dispatch({ type: AUTH_LOGOUT_SUCCESS })
    } catch (e) {
      dispatch({ type: AUTH_LOGOUT_ERROR, payload: e.message })
    }
  }

export const confirmNewEmail =
  (confirmation_token: string) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: CONFIRM_EMAIL })
      await confirmEmail(confirmation_token)
      dispatch({ type: CONFIRM_EMAIL_SUCCESS })
    } catch (e) {
      dispatch({
        type: CONFIRM_EMAIL_ERROR,
        payload: e.message?.confirmation_token || 'Email was not confirmed',
      })
    }
  }

export const forgotUserPassword =
  (email: string) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: FORGOT_PASSWORD })
      await forgotPassword(email)
      dispatch({ type: FORGOT_PASSWORD_SUCCESS })
    } catch (e) {
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: e?.response?.errors?.email[0] || 'Something went wrong',
      })
    }
  }

export const resetUserPassword =
  (
    password: string,
    password_confirmation: string,
    reset_password_token: string,
  ) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: RESET_PASSWORD })
      await resetPassword(password, password_confirmation, reset_password_token)
      dispatch({ type: RESET_PASSWORD_SUCCESS })
    } catch (e) {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: e?.response?.errors?.email[0] || 'Password reset failed',
      })
    }
  }

export const createNewUserPassword =
  (password: string, invitation_token: string) =>
  async (dispatch: Dispatch<TAuthAction>): Promise<void> => {
    try {
      dispatch({ type: PASSWORD_CREATE })
      await createNewPassword(invitation_token, password)
      dispatch({ type: PASSWORD_CREATE_SUCCESS })
    } catch {
      dispatch({
        type: PASSWORD_CREATE_ERROR,
        payload: 'Password was not created',
      })
    }
  }
