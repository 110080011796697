import * as React from 'react'
import classNames from 'classnames'

//styles
import './NotificationItem.scss'

//components
import Button from '../../Button/Button'

//utils
import { links } from '../../../utils/constants'
import { formatDate } from '../../../utils/helpers'

//assets
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow_right.svg'

//interfaces
interface INotificationItemProps {
  id: string
  text: string
  time: string
  small?: boolean
  onDismiss?: () => void
}

const NotificationItem: React.FC<INotificationItemProps> = ({
  id,
  text,
  time,
  small = false,
  onDismiss,
}) => {
  return (
    <li className={classNames('notification-item', { small: small })}>
      <div className="item-info">
        <p>{text}</p>
        <p className="footnote">{formatDate(time)}</p>
      </div>
      {small && (
        <Button onClick={onDismiss} design="flat" color="red">
          Dismiss
        </Button>
      )}
      <Button
        href={links.referral(id)}
        design="outlined"
        color="black"
        size="small"
      >
        View Referral
        <ArrowIcon />
      </Button>
    </li>
  )
}

export default NotificationItem
