export const DASHBOARD_FETCH = 'DASHBOARD_FETCH'
export const DASHBOARD_FETCH_ERROR = 'DASHBOARD_FETCH_ERROR'
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS'

export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH'
export const NOTIFICATIONS_FETCH_ERROR = 'NOTIFICATIONS_FETCH_ERROR'
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS'

export const NOTIFICATIONS_UNREAD_FETCH = 'NOTIFICATIONS_UNREAD_FETCH'
export const NOTIFICATIONS_UNREAD_FETCH_ERROR =
  'NOTIFICATIONS_UNREAD_FETCH_ERROR'
export const NOTIFICATIONS_UNREAD_FETCH_SUCCESS =
  'NOTIFICATIONS_UNREAD_FETCH_SUCCESS'

export const NOTIFICATIONS_READ = 'NOTIFICATIONS_READ'
export const NOTIFICATIONS_READ_ERROR = 'NOTIFICATIONS_READ_ERROR'
export const NOTIFICATIONS_READ_SUCCESS = 'NOTIFICATIONS_READ_SUCCESS'

export interface IDashboardState {
  dashboard: IDashboard
  notifications: INotification[]
  unreadNotifications: INotification[]
  loading: boolean
  loadingUnread: boolean
  error: string | null
}

interface IDashboardAction {
  type: typeof DASHBOARD_FETCH
}
interface IDashboardSuccessAction {
  type: typeof DASHBOARD_FETCH_SUCCESS
  payload: IDashboard
}
interface IDashboardErrorAction {
  type: typeof DASHBOARD_FETCH_ERROR
  payload: string
}

interface INotificationsAction {
  type: typeof NOTIFICATIONS_FETCH
}
interface INotificationsSuccessAction {
  type: typeof NOTIFICATIONS_FETCH_SUCCESS
  payload: INotification[]
}
interface INotificationsErrorAction {
  type: typeof NOTIFICATIONS_FETCH_ERROR
  payload: string
}

interface INotificationsUnreadAction {
  type: typeof NOTIFICATIONS_UNREAD_FETCH
}
interface INotificationsUnreadSuccessAction {
  type: typeof NOTIFICATIONS_UNREAD_FETCH_SUCCESS
  payload: INotification[]
}
interface INotificationsUnreadErrorAction {
  type: typeof NOTIFICATIONS_UNREAD_FETCH_ERROR
  payload: string
}

interface INotificationsReadAction {
  type: typeof NOTIFICATIONS_READ
}
interface INotificationsReadSuccessAction {
  type: typeof NOTIFICATIONS_READ_SUCCESS
  payload: string
}
interface INotificationsReadErrorAction {
  type: typeof NOTIFICATIONS_READ_ERROR
  payload: string
}

export type TDashboardAction =
  | IDashboardAction
  | IDashboardSuccessAction
  | IDashboardErrorAction
  | INotificationsAction
  | INotificationsSuccessAction
  | INotificationsErrorAction
  | INotificationsUnreadAction
  | INotificationsUnreadSuccessAction
  | INotificationsUnreadErrorAction
  | INotificationsReadAction
  | INotificationsReadSuccessAction
  | INotificationsReadErrorAction
