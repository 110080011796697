import {
  FACILITIES_FETCH,
  FACILITIES_FETCH_ERROR,
  FACILITIES_FETCH_SUCCESS,
  IFacilitiesState,
  TFacilitiesAction,
} from '../../types/facilitiesTypes'

const initialState: IFacilitiesState = {
  facilities: [],
  loading: false,
  error: null,
}

export const facilitiesReducer = (
  state = initialState,
  action: TFacilitiesAction,
): IFacilitiesState => {
  switch (action.type) {
    case FACILITIES_FETCH:
      return { ...state, loading: true }
    case FACILITIES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        facilities: action.payload,
      }
    }
    case FACILITIES_FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload }
    }

    default:
      return state
  }
}
