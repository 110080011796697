import { Dispatch } from 'redux'

//services
import {
  adminGetStaff,
  createAccount,
  deleteAccount,
  editAccount,
  editAccountStatuses,
  getAccountReferrals,
  getAccounts,
  getOneAccount,
  uploadAvatar,
} from '../../../services/accountService'

//types
import {
  ACCOUNT_CREATE,
  ACCOUNT_CREATE_ERROR,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_DELETE,
  ACCOUNT_DELETE_ERROR,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_FETCH,
  ACCOUNT_FETCH_ERROR,
  ACCOUNT_FETCH_ONE,
  ACCOUNT_FETCH_ONE_ERROR,
  ACCOUNT_FETCH_ONE_SUCCESS,
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_GET_REFERRALS,
  ACCOUNT_GET_REFERRALS_ERROR,
  ACCOUNT_GET_REFERRALS_SUCCESS,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_STATUS,
  ACCOUNT_UPDATE_STATUS_ERROR,
  ACCOUNT_UPDATE_STATUS_SUCCESS,
  ACCOUNT_UPDATE_SUCCESS,
  AVATAR_UPLOAD,
  AVATAR_UPLOAD_ERROR,
  AVATAR_UPLOAD_SUCCESS,
  TAccountAction,
} from '../../types/accountTypes'

export const fetchAccounts = (query: IQueryParams = {}) => {
  return async (dispatch: Dispatch<TAccountAction>): Promise<IPaginator> => {
    try {
      dispatch({ type: ACCOUNT_FETCH })
      const { data, meta } = await getAccounts(query)
      dispatch({
        type: ACCOUNT_FETCH_SUCCESS,
        payload: { accounts: data as IAccount[], admins: {} as IAdminsMeta },
      })
      return meta
    } catch (e) {
      dispatch({ type: ACCOUNT_FETCH_ERROR, payload: e.message })
      return {} as IPaginator
    }
  }
}

export const fetchAccount = (id: string) => {
  return async (dispatch: Dispatch<TAccountAction>): Promise<void> => {
    try {
      dispatch({ type: ACCOUNT_FETCH_ONE })
      const { data, meta } = await getOneAccount(id)
      dispatch({
        type: ACCOUNT_FETCH_ONE_SUCCESS,
        payload: {
          account: data as IAccount,
          admins: meta.admin as IAdminsMeta,
        },
      })
    } catch (e) {
      dispatch({ type: ACCOUNT_FETCH_ONE_ERROR, payload: e.message })
    }
  }
}

export const fetchAccountReferrals =
  (id: string, params: IQueryParams) =>
  async (dispatch: Dispatch<TAccountAction>): Promise<IPaginator> => {
    try {
      dispatch({ type: ACCOUNT_GET_REFERRALS })
      const { data, meta } = await getAccountReferrals(id, params)
      dispatch({
        type: ACCOUNT_GET_REFERRALS_SUCCESS,
        payload: data as IReferral[],
      })
      return meta
    } catch (e) {
      dispatch({ type: ACCOUNT_GET_REFERRALS_ERROR, payload: e.message })
      return {} as IPaginator
    }
  }

export const createNewAccount =
  (formData: Record<string, string | boolean>) =>
  async (dispatch: Dispatch<TAccountAction>): Promise<IAccount> => {
    try {
      dispatch({ type: ACCOUNT_CREATE })
      const response = await createAccount({
        account: {
          ...formData,
          user_attributes: {
            email: formData.email as string,
            admin: formData.admin as boolean,
          },
        },
      } as ICreateAccountForm)
      dispatch({ type: ACCOUNT_CREATE_SUCCESS, payload: response })
      return response
    } catch (e) {
      dispatch({ type: ACCOUNT_CREATE_ERROR, payload: e.message })
      return {} as IAccount
    }
  }

export const removeAccount =
  (id: string, confirmation: string) =>
  async (dispatch: Dispatch<TAccountAction>): Promise<void> => {
    try {
      dispatch({ type: ACCOUNT_DELETE })
      await deleteAccount(id, confirmation)
      dispatch({ type: ACCOUNT_DELETE_SUCCESS, payload: id })
    } catch (e) {
      dispatch({ type: ACCOUNT_DELETE_ERROR, payload: e.message })
    }
  }

export const editUserAccount =
  (id: string, updatedData: Partial<IAccountAttributes>) =>
  async (dispatch: Dispatch<TAccountAction>): Promise<void> => {
    try {
      dispatch({ type: ACCOUNT_UPDATE })
      const response = await editAccount(id, updatedData)
      dispatch({
        type: ACCOUNT_UPDATE_SUCCESS,
        payload: response,
      })
    } catch (e) {
      dispatch({ type: ACCOUNT_UPDATE_ERROR, payload: e.message })
    }
  }

export const editUserAccountStatuses =
  (
    account_id: string,
    user_id: string,
    updatedData: Partial<IAccountAttributes>,
  ) =>
  async (dispatch: Dispatch<TAccountAction>): Promise<void> => {
    try {
      dispatch({ type: ACCOUNT_UPDATE_STATUS })
      const response = await editAccountStatuses(
        account_id,
        user_id,
        updatedData,
      )
      dispatch({ type: ACCOUNT_UPDATE_STATUS_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: ACCOUNT_UPDATE_STATUS_ERROR, payload: e.message })
    }
  }

export const uploadUserAvatar =
  (account_id: string, avatar: Blob) =>
  async (dispatch: Dispatch<TAccountAction>): Promise<void> => {
    try {
      dispatch({ type: AVATAR_UPLOAD })
      const response = await uploadAvatar(account_id, avatar)
      dispatch({ type: AVATAR_UPLOAD_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: AVATAR_UPLOAD_ERROR, payload: e.message })
    }
  }

export const fetchAccountsAdmin = (query: IQueryParams = {}) => {
  return async (dispatch: Dispatch<TAccountAction>): Promise<IPaginator> => {
    try {
      dispatch({ type: ACCOUNT_FETCH })
      const { data, meta } = await adminGetStaff(query)
      dispatch({
        type: ACCOUNT_FETCH_SUCCESS,
        payload: {
          accounts: data as IAccount[],
          admins: (meta as IPaginatorWithAdmins).admin,
        },
      })
      return meta
    } catch (e) {
      dispatch({ type: ACCOUNT_FETCH_ERROR, payload: e.message })
      return {} as IPaginator
    }
  }
}
