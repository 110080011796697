export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'

export const AUTH_OTP = 'AUTH_OTP'
export const AUTH_OTP_SUCCESS = 'AUTH_OTP_SUCCESS'
export const AUTH_OTP_ERROR = 'AUTH_OTP_ERROR'

export const AUTH_GET_CURRENT = 'AUTH_GET_CURRENT'
export const AUTH_GET_CURRENT_SUCCESS = 'AUTH_GET_CURRENT_SUCCESS'
export const AUTH_GET_CURRENT_ERROR = 'AUTH_GET_CURRENT_ERROR'

export const AUTH_UPDATE_CURRENT = 'AUTH_UPDATE_CURRENT'
export const AUTH_UPDATE_CURRENT_SUCCESS = 'AUTH_UPDATE_CURRENT_SUCCESS'
export const AUTH_UPDATE_CURRENT_ERROR = 'AUTH_UPDATE_CURRENT_ERROR'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR'

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const PASSWORD_CREATE = 'PASSWORD_CREATE'
export const PASSWORD_CREATE_SUCCESS = 'PASSWORD_CREATE_SUCCESS'
export const PASSWORD_CREATE_ERROR = 'PASSWORD_CREATE_ERROR'

export interface IAuthState {
  currentUser: IAccount
  loading: boolean
  error: string | undefined
}

interface IAuthLoginAction {
  type: typeof AUTH_LOGIN
}
interface IAuthLoginSuccessAction {
  type: typeof AUTH_LOGIN_SUCCESS
  payload: IAccount
}
interface IAuthLoginErrorAction {
  type: typeof AUTH_LOGIN_ERROR
  payload: string
}

interface IAuthOTPAction {
  type: typeof AUTH_OTP
}
interface IAuthOTPSuccessAction {
  type: typeof AUTH_OTP_SUCCESS
}
interface IAuthOTPErrorAction {
  type: typeof AUTH_OTP_ERROR
  payload: string
}

interface IAuthGetCurrentAction {
  type: typeof AUTH_GET_CURRENT
}
interface IAuthGetCurrentSuccessAction {
  type: typeof AUTH_GET_CURRENT_SUCCESS
  payload: IAccount
}
interface IAuthGetCurrentErrorAction {
  type: typeof AUTH_GET_CURRENT_ERROR
  payload: string
}

interface IAuthUpdateCurrentAction {
  type: typeof AUTH_UPDATE_CURRENT
}
interface IAuthUpdateCurrentSuccessAction {
  type: typeof AUTH_UPDATE_CURRENT_SUCCESS
  payload: IAccount
}
interface IAuthUpdateCurrentErrorAction {
  type: typeof AUTH_UPDATE_CURRENT_ERROR
  payload: string
}

interface IAuthGetCurrentAction {
  type: typeof AUTH_GET_CURRENT
}
interface IAuthGetCurrentSuccessAction {
  type: typeof AUTH_GET_CURRENT_SUCCESS
  payload: IAccount
}
interface IAuthGetCurrentErrorAction {
  type: typeof AUTH_GET_CURRENT_ERROR
  payload: string
}

interface IAuthLogoutAction {
  type: typeof AUTH_LOGOUT
}
interface IAuthLogoutSuccessAction {
  type: typeof AUTH_LOGOUT_SUCCESS
}
interface IAuthLogoutErrorAction {
  type: typeof AUTH_LOGOUT_ERROR
  payload: string
}

interface IConfirmEmailAction {
  type: typeof CONFIRM_EMAIL
}
interface IConfirmEmailSuccessAction {
  type: typeof CONFIRM_EMAIL_SUCCESS
}
interface IConfirmEmailErrorAction {
  type: typeof CONFIRM_EMAIL_ERROR
  payload: string
}

interface IForgotPasswordAction {
  type: typeof FORGOT_PASSWORD
}
interface IForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_SUCCESS
}
interface IForgotPasswordErrorAction {
  type: typeof FORGOT_PASSWORD_ERROR
  payload: string
}

interface IResetPasswordAction {
  type: typeof RESET_PASSWORD
}
interface IResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS
}
interface IResetPasswordErrorAction {
  type: typeof RESET_PASSWORD_ERROR
  payload: string
}

interface IPasswordCreateAction {
  type: typeof PASSWORD_CREATE
}
interface IPasswordCreateSuccessAction {
  type: typeof PASSWORD_CREATE_SUCCESS
}
interface IPasswordCreateErrorAction {
  type: typeof PASSWORD_CREATE_ERROR
  payload: string
}

export type TAuthAction =
  | IAuthLoginAction
  | IAuthLoginSuccessAction
  | IAuthLoginErrorAction
  | IAuthOTPAction
  | IAuthOTPSuccessAction
  | IAuthOTPErrorAction
  | IAuthGetCurrentAction
  | IAuthGetCurrentSuccessAction
  | IAuthGetCurrentErrorAction
  | IAuthUpdateCurrentAction
  | IAuthUpdateCurrentSuccessAction
  | IAuthUpdateCurrentErrorAction
  | IAuthLogoutAction
  | IAuthLogoutSuccessAction
  | IAuthLogoutErrorAction
  | IConfirmEmailAction
  | IConfirmEmailSuccessAction
  | IConfirmEmailErrorAction
  | IForgotPasswordAction
  | IForgotPasswordSuccessAction
  | IForgotPasswordErrorAction
  | IResetPasswordAction
  | IResetPasswordSuccessAction
  | IResetPasswordErrorAction
  | IPasswordCreateAction
  | IPasswordCreateSuccessAction
  | IPasswordCreateErrorAction
