import { Dispatch } from 'redux'

//services
import {
  getDashboard,
  getNewNotifications,
  getNotifications,
  markNotification,
} from '../../../services/dashboardService'

//types
import {
  DASHBOARD_FETCH,
  DASHBOARD_FETCH_ERROR,
  DASHBOARD_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH,
  NOTIFICATIONS_FETCH_ERROR,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_READ_ERROR,
  NOTIFICATIONS_READ_SUCCESS,
  NOTIFICATIONS_UNREAD_FETCH,
  NOTIFICATIONS_UNREAD_FETCH_ERROR,
  NOTIFICATIONS_UNREAD_FETCH_SUCCESS,
  TDashboardAction,
} from '../../types/dashboardTypes'

export const fetchDashboard = (time = '30') => {
  return async (dispatch: Dispatch<TDashboardAction>): Promise<void> => {
    try {
      dispatch({ type: DASHBOARD_FETCH })
      const data = await getDashboard(time)
      dispatch({ type: DASHBOARD_FETCH_SUCCESS, payload: data })
    } catch (e) {
      dispatch({ type: DASHBOARD_FETCH_ERROR, payload: e.message })
    }
  }
}

export const fetchNotifications = (params: IQueryParams) => {
  return async (dispatch: Dispatch<TDashboardAction>): Promise<IPaginator> => {
    try {
      dispatch({ type: NOTIFICATIONS_FETCH })
      const { data, meta } = await getNotifications(params)
      dispatch({ type: NOTIFICATIONS_FETCH_SUCCESS, payload: data })
      return meta
    } catch (e) {
      dispatch({
        type: NOTIFICATIONS_FETCH_ERROR,
        payload: e.message,
      })
      return {} as IPaginator
    }
  }
}

export const fetchUnreadNotifications = () => {
  return async (dispatch: Dispatch<TDashboardAction>): Promise<void> => {
    try {
      dispatch({ type: NOTIFICATIONS_UNREAD_FETCH })
      const data = await getNewNotifications()
      dispatch({ type: NOTIFICATIONS_UNREAD_FETCH_SUCCESS, payload: data })
    } catch (e) {
      dispatch({
        type: NOTIFICATIONS_UNREAD_FETCH_ERROR,
        payload: e.message,
      })
    }
  }
}

export const markAsRead = (id: string) => {
  return async (dispatch: Dispatch<TDashboardAction>): Promise<void> => {
    try {
      dispatch({ type: NOTIFICATIONS_READ })
      const data = await markNotification(id)
      dispatch({ type: NOTIFICATIONS_READ_SUCCESS, payload: data[0].id })
    } catch (e) {
      dispatch({
        type: NOTIFICATIONS_READ_ERROR,
        payload: e.message,
      })
    }
  }
}
