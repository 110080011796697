import { Dispatch } from 'redux'

//types
import {
  REFERRAL_FETCH,
  REFERRAL_FETCH_ERROR,
  REFERRAL_FETCH_ONE,
  REFERRAL_FETCH_ONE_ERROR,
  REFERRAL_FETCH_ONE_SUCCESS,
  REFERRAL_FETCH_SUCCESS,
  REFERRAL_UPDATE_ONE,
  REFERRAL_UPDATE_ONE_ERROR,
  REFERRAL_UPDATE_ONE_SUCCESS,
  TReferralAction,
} from '../../types/referralTypes'

//services
import {
  getOneReferral,
  getReferrals,
  updateReferral,
} from '../../../services/referralService'

export const fetchReferrals = (query: IQueryParams = {}) => {
  return async (dispatch: Dispatch<TReferralAction>): Promise<IPaginator> => {
    try {
      dispatch({ type: REFERRAL_FETCH })
      const { data, meta } = await getReferrals(query)
      dispatch({ type: REFERRAL_FETCH_SUCCESS, payload: data as IReferral[] })
      return meta
    } catch (e) {
      dispatch({ type: REFERRAL_FETCH_ERROR, payload: e.message })
      return {} as IPaginator
    }
  }
}

export const fetchReferral = (id: string) => {
  return async (dispatch: Dispatch<TReferralAction>): Promise<void> => {
    try {
      dispatch({ type: REFERRAL_FETCH_ONE })
      const response = await getOneReferral(id)
      dispatch({ type: REFERRAL_FETCH_ONE_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: REFERRAL_FETCH_ONE_ERROR, payload: e.message })
    }
  }
}

export const editReferral = (id: string, data: Record<string, string>) => {
  return async (dispatch: Dispatch<TReferralAction>): Promise<void> => {
    try {
      dispatch({ type: REFERRAL_UPDATE_ONE })
      const response = await updateReferral(id, data)
      dispatch({ type: REFERRAL_UPDATE_ONE_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: REFERRAL_UPDATE_ONE_ERROR, payload: e.message })
    }
  }
}
