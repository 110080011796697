import {
  AUTH_GET_CURRENT,
  AUTH_GET_CURRENT_ERROR,
  AUTH_GET_CURRENT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_UPDATE_CURRENT,
  AUTH_UPDATE_CURRENT_ERROR,
  AUTH_UPDATE_CURRENT_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  PASSWORD_CREATE,
  PASSWORD_CREATE_ERROR,
  PASSWORD_CREATE_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  AUTH_OTP_SUCCESS,
  AUTH_OTP,
  AUTH_OTP_ERROR,
  IAuthState,
  TAuthAction,
} from '../../types/authTypes'

const initialState: IAuthState = {
  currentUser: {} as IAccount,
  loading: false,
  error: undefined,
}

export const authReducer = (
  state = initialState,
  action: TAuthAction,
): IAuthState => {
  switch (action.type) {
    case AUTH_OTP:
      return { ...state, loading: true }
    case AUTH_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      }
    case AUTH_OTP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case AUTH_LOGIN:
      return { ...state, loading: true }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        currentUser: action.payload,
      }
    case AUTH_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case AUTH_GET_CURRENT:
      return { ...state, loading: true }
    case AUTH_GET_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      }
    case AUTH_GET_CURRENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case AUTH_UPDATE_CURRENT:
      return { ...state, loading: true }
    case AUTH_UPDATE_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      }
    case AUTH_UPDATE_CURRENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case AUTH_LOGOUT:
      return { ...state, loading: true }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {} as IAccount,
      }
    case AUTH_LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CONFIRM_EMAIL:
      return { ...state, loading: true }
    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case FORGOT_PASSWORD:
      return { ...state, loading: true }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case RESET_PASSWORD:
      return { ...state, loading: true }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case PASSWORD_CREATE:
      return { ...state, loading: true }
    case PASSWORD_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case PASSWORD_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
