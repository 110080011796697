import baseService from './baseService'

export const getDashboard = async (time: string): Promise<IDashboard> => {
  const {
    data: {
      data,
      meta: { dashboard },
    },
  } = await baseService.get('/api/dashboard/home', {
    params: { filter_by: time },
  })
  dashboard.referrals = data
  return dashboard
}

export const getNotifications = async (
  params: IQueryParams,
): Promise<IPaginatedResponse<INotification>> => {
  const { data } = await baseService.get('/api/notifications', {
    params: params,
  })
  return data
}

export const getNewNotifications = async (): Promise<INotification[]> => {
  const {
    data: { data },
  } = await baseService.get('/api/notifications', { params: { unread: true } })
  return data
}

export const markNotification = async (
  id: string,
): Promise<INotification[]> => {
  const {
    data: { data },
  } = await baseService.post('/api/notifications', {
    notifications: { ids: [id] },
  })
  return data
}
