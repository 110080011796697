import * as React from 'react'
import { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

//styles
import './Button.scss'

//interfaces
interface IButtonProps {
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'red' | 'black'
  design?: 'main' | 'outlined' | 'flat'
  size?: 'regular' | 'small' | 'large'
  type?: 'button' | 'submit'
  onClick?: MouseEventHandler<HTMLButtonElement>
  href?: string
  newPage?: boolean
  disabled?: boolean
  external?: boolean
}

const Button: React.FC<IButtonProps> = ({
  design = 'main',
  color = 'primary',
  size = 'regular',
  type = 'button',
  onClick,
  href,
  newPage,
  disabled,
  external,
  children,
}) => {
  const styles = classNames(`button ${color}`, {
    [size]: size !== 'regular',
    [design]: design !== 'main',
  })

  const renderButton = () => (
    <button
      className={styles}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  )

  const renderLink = () => {
    const linkProps: { [key: string]: string } = {}
    if (newPage) {
      linkProps.target = 'blank'
      linkProps.rel = 'noopener noreferrer'
    }

    return external ? (
      <a className={styles} href={href} {...linkProps}>
        {children}
      </a>
    ) : (
      <Link className={styles} to={href as string} {...linkProps}>
        {children}
      </Link>
    )
  }

  return href ? renderLink() : renderButton()
}

export default Button
