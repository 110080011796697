import * as React from 'react'
import { useEffect, useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

//utils
import { useActions } from '../utils/hooks/useActions'
import { useSelectorTyped } from '../utils/hooks/useSelectorTyped'

//services
import { getUserToken } from '../services/baseService'

//components
import AuthRoutes from './AuthRoutes'
import AppRoutes from './AppRoutes'

const VNSCRouter: React.FC = () => {
  const { id, attributes } = useSelectorTyped(
    ({ authUser }) => authUser.currentUser,
  )
  const { getCurrentUser } = useActions()
  const isSent = useRef(false)

  useEffect(() => {
    if (!id && getUserToken()) {
      if (!isSent.current) {
        getCurrentUser()
        isSent.current = true
      }
    }
  }, [id, getCurrentUser])

  return (
    <Router>
      {getUserToken() ? (
        id && <AppRoutes isAdmin={attributes?.admin} />
      ) : (
        <AuthRoutes />
      )}
    </Router>
  )
}

export default VNSCRouter
