import { Dispatch } from 'redux'

//types
import {
  FACILITIES_FETCH,
  FACILITIES_FETCH_ERROR,
  FACILITIES_FETCH_SUCCESS,
  TFacilitiesAction,
} from '../../types/facilitiesTypes'

//services
import { getFacilities } from '../../../services/facilitiesService'

export const fetchFacilities = () => {
  return async (dispatch: Dispatch<TFacilitiesAction>): Promise<void> => {
    try {
      dispatch({ type: FACILITIES_FETCH })
      const data = await getFacilities()
      dispatch({
        type: FACILITIES_FETCH_SUCCESS,
        payload: data,
      })
    } catch (e) {
      dispatch({ type: FACILITIES_FETCH_ERROR, payload: e.message })
    }
  }
}
