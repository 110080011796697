export const ACCOUNT_FETCH = 'ACCOUNT_FETCH'
export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS'
export const ACCOUNT_FETCH_ERROR = 'ACCOUNT_FETCH_ERROR'

export const ACCOUNT_FETCH_ONE = 'ACCOUNT_FETCH_ONE'
export const ACCOUNT_FETCH_ONE_SUCCESS = 'ACCOUNT_FETCH_ONE_SUCCESS'
export const ACCOUNT_FETCH_ONE_ERROR = 'ACCOUNT_FETCH_ONE_ERROR'

export const ACCOUNT_GET_REFERRALS = 'ACCOUNT_GET_REFERRALS'
export const ACCOUNT_GET_REFERRALS_SUCCESS = 'ACCOUNT_GET_REFERRALS_SUCCESS'
export const ACCOUNT_GET_REFERRALS_ERROR = 'ACCOUNT_GET_REFERRALS_ERROR'

export const ACCOUNT_CREATE = 'ACCOUNT_CREATE'
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS'
export const ACCOUNT_CREATE_ERROR = 'ACCOUNT_CREATE_ERROR'

export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE'
export const ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR'
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS'

export const AVATAR_UPLOAD = 'AVATAR_UPLOAD'
export const AVATAR_UPLOAD_ERROR = 'AVATAR_UPLOAD_ERROR'
export const AVATAR_UPLOAD_SUCCESS = 'AVATAR_UPLOAD_SUCCESS'

export const ACCOUNT_UPDATE_STATUS = 'ACCOUNT_UPDATE_STATUS'
export const ACCOUNT_UPDATE_STATUS_ERROR = 'ACCOUNT_UPDATE_STATUS_ERROR'
export const ACCOUNT_UPDATE_STATUS_SUCCESS = 'ACCOUNT_UPDATE_STATUS_SUCCESS'

export const ACCOUNT_DELETE = 'ACCOUNT_DELETE'
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR'
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS'

export interface IAccountState {
  accounts: IAccount[]
  selectedAccount: IAccount
  accountReferrals: IReferral[]
  loading: boolean
  error: string | null
  admins: IAdminsMeta
}

interface IAccountFetchAction {
  type: typeof ACCOUNT_FETCH
}
interface IAccountFetchSuccessAction {
  type: typeof ACCOUNT_FETCH_SUCCESS
  payload: { accounts: IAccount[]; admins: IAdminsMeta }
}
interface IAccountFetchErrorAction {
  type: typeof ACCOUNT_FETCH_ERROR
  payload: string
}

interface IAccountFetchOneAction {
  type: typeof ACCOUNT_FETCH_ONE
}
interface IAccountFetchOneSuccessAction {
  type: typeof ACCOUNT_FETCH_ONE_SUCCESS
  payload: { account: IAccount; admins: IAdminsMeta }
}
interface IAccountFetchOneErrorAction {
  type: typeof ACCOUNT_FETCH_ONE_ERROR
  payload: string
}

interface IAccountGetReferralsAction {
  type: typeof ACCOUNT_GET_REFERRALS
}
interface IAccountGetReferralsSuccessAction {
  type: typeof ACCOUNT_GET_REFERRALS_SUCCESS
  payload: IReferral[]
}
interface IAccountGetReferralsErrorAction {
  type: typeof ACCOUNT_GET_REFERRALS_ERROR
  payload: string
}

interface IAccountCreateAction {
  type: typeof ACCOUNT_CREATE
}
interface IAccountCreateSuccessAction {
  type: typeof ACCOUNT_CREATE_SUCCESS
  payload: IAccount
}
interface IAccountCreateErrorAction {
  type: typeof ACCOUNT_CREATE_ERROR
  payload: string
}

interface IAccountUpdateAction {
  type: typeof ACCOUNT_UPDATE
}
interface IAccountUpdateSuccessAction {
  type: typeof ACCOUNT_UPDATE_SUCCESS
  payload: IAccount
}
interface IAccountUpdateErrorAction {
  type: typeof ACCOUNT_UPDATE_ERROR
  payload: string
}

interface IAvatarUploadAction {
  type: typeof AVATAR_UPLOAD
}
interface IAvatarUploadSuccessAction {
  type: typeof AVATAR_UPLOAD_SUCCESS
  payload: IAccount
}
interface IAvatarUploadErrorAction {
  type: typeof AVATAR_UPLOAD_ERROR
  payload: string
}

interface IAccountUpdateStatusAction {
  type: typeof ACCOUNT_UPDATE_STATUS
}
interface IAccountUpdateStatusSuccessAction {
  type: typeof ACCOUNT_UPDATE_STATUS_SUCCESS
  payload: IAccount
}
interface IAccountUpdateStatusErrorAction {
  type: typeof ACCOUNT_UPDATE_STATUS_ERROR
  payload: string
}

interface IAccountDeleteAction {
  type: typeof ACCOUNT_DELETE
}
interface IAccountDeleteSuccessAction {
  type: typeof ACCOUNT_DELETE_SUCCESS
  payload: string
}
interface IAccountDeleteErrorAction {
  type: typeof ACCOUNT_DELETE_ERROR
  payload: string
}

export type TAccountAction =
  | IAccountFetchAction
  | IAccountFetchSuccessAction
  | IAccountFetchErrorAction
  | IAccountFetchOneAction
  | IAccountFetchOneSuccessAction
  | IAccountFetchOneErrorAction
  | IAccountCreateAction
  | IAccountCreateSuccessAction
  | IAccountCreateErrorAction
  | IAccountUpdateAction
  | IAccountUpdateSuccessAction
  | IAccountUpdateErrorAction
  | IAccountUpdateStatusAction
  | IAccountUpdateStatusSuccessAction
  | IAccountUpdateStatusErrorAction
  | IAccountDeleteAction
  | IAccountDeleteSuccessAction
  | IAccountDeleteErrorAction
  | IAccountGetReferralsAction
  | IAccountGetReferralsSuccessAction
  | IAccountGetReferralsErrorAction
  | IAvatarUploadAction
  | IAvatarUploadSuccessAction
  | IAvatarUploadErrorAction
